.dashboard {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.dashboard-username > h2 {
    font-size: 32px;
    margin-bottom: 35px;
}

.learn-words-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    min-width: 300px;
}

.word-display {
    font-size: 24px;
}

.input-label {
    margin-bottom: 8px;
}

.submit-word {
    height: 40px;
    width: 200px;
    font-size: 16px;
    border-radius: 5px;
}

.next-button {
    height: 40px;
    width: 200px;
    font-size: 16px;
    border-radius: 5px;
}

.hidden {
    display: hidden;
}

#userAnswer {
    width: 200px;
    height: 40px;
    font-size: 16px;
    border-radius: 5px;
    padding: 2px 8px;
    margin-bottom: 16px;
    border: 1px solid black;
}