.homeReg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 5px;
}