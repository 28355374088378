.header-bar{
  text-align: center;
  background-color: palegreen;
  position: relative;
  top: 0px;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid black;
  background: url('https://www.kcl.ac.uk/newimages/sspp/Brazil-Flag-Main.jpg') left top no-repeat, url('https://ak5.picdn.net/shutterstock/videos/2623775/thumb/1.jpg') right bottom no-repeat;
  background-size: 50%, 50%;
}

.header-bar > h1 {
  border: solid 1px black;
  border-radius: 5px;
  background-color: white;
  display: block;
  padding: 5px;
  width: 360px;
  margin-right: auto;
  margin-left: auto;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif
}

p {
  background-color: white;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  padding: 2px;
}

.logout-button{
  height: 35px;
  width: 150px;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 470px){
  .header-bar{
    background: url('https://www.kcl.ac.uk/newimages/sspp/Brazil-Flag-Main.jpg') left no-repeat, url('https://ak5.picdn.net/shutterstock/videos/2623775/thumb/1.jpg') right no-repeat;
    background-size: cover, cover;
  }
  .header-bar > h1 {
    padding: 1px;
    width: 60%;
  }
} 
label {
    font-size: 20px;
}
.home {
    position: relative;
    margin-top: 15%;
    box-sizing: border-box;
    border: 2px solid black;
    border-radius: 5px;
    padding: 1%;
    float: right;
    margin-right: 10%;
    text-align: center;
}

.homeContainer {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.informercial {
  position: relative;
  margin-top: 12.5%;
  display: inline-block;
  margin-left: 10%;
}

.blurb {
  margin: 0;
  display: inline-block;
  width: 400px;
  line-height: 34px;
}

.linkToRegister {
  font-size: 20px;
}

label {
  display: block;
}


#password {
  height: 40px;
  width: 200px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid black;
  padding: 8px;
}

#passwordConfirm {
  height: 40px;
  width: 200px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid black;
  padding: 8px;
}

#username {
  height: 40px;
  width: 200px;
  font-size: 16px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 8px;
}

.form-input{
  margin-bottom: 10px;
}

.login-button{
  height: 40px;
  width: 200px;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 5px;
}


@media (max-width: 768px) {
  .homeContainer {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column-reverse;
            flex-direction: column-reverse;
    -webkit-align-items: center;
            align-items: center;
  }

  .home {
    padding: 15px;
    margin: 50px 0;
  }

  .form-input{
    margin-bottom: 10px;
  }

  .informercial {
    max-width: 500px;
    margin: 35px 35px 15px 35px;
    text-align: center;
  }
}

@media (max-width: 400px) {
  .informercial {
    max-width: 300px;
  }

  .blurb {
    max-width: 300px;
  }
}

.progressButton{
  height: 40px;
  width: 200px;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 10px;
}
.dashboard {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
}

.dashboard-username > h2 {
    font-size: 32px;
    margin-bottom: 35px;
}

.learn-words-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-align-items: center;
            align-items: center;
    -webkit-justify-content: center;
            justify-content: center;
    text-align: center;
    min-width: 300px;
}

.word-display {
    font-size: 24px;
}

.input-label {
    margin-bottom: 8px;
}

.submit-word {
    height: 40px;
    width: 200px;
    font-size: 16px;
    border-radius: 5px;
}

.next-button {
    height: 40px;
    width: 200px;
    font-size: 16px;
    border-radius: 5px;
}

.hidden {
    display: hidden;
}

#userAnswer {
    width: 200px;
    height: 40px;
    font-size: 16px;
    border-radius: 5px;
    padding: 2px 8px;
    margin-bottom: 16px;
    border: 1px solid black;
}
.homeReg {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 500px;
  text-align: center;
  font-size: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-top: 5px;
}
.registerButton {
    width: 200px;
    height: 40px;
    font-size: 16px;
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 5px;
}
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

* {
  box-sizing: border-box;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}
