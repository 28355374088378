.header-bar{
  text-align: center;
  background-color: palegreen;
  position: relative;
  top: 0px;
  width: 100%;
  box-sizing: border-box;
  border: 2px solid black;
  background: url('https://www.kcl.ac.uk/newimages/sspp/Brazil-Flag-Main.jpg') left top no-repeat, url('https://ak5.picdn.net/shutterstock/videos/2623775/thumb/1.jpg') right bottom no-repeat;
  background-size: 50%, 50%;
}

.header-bar > h1 {
  border: solid 1px black;
  border-radius: 5px;
  background-color: white;
  display: block;
  padding: 5px;
  width: 360px;
  margin-right: auto;
  margin-left: auto;
  font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif
}

p {
  background-color: white;
  width: 200px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  padding: 2px;
}

.logout-button{
  height: 35px;
  width: 150px;
  font-size: 16px;
  border-radius: 5px;
  margin-bottom: 5px;
}

@media only screen and (max-width: 470px){
  .header-bar{
    background: url('https://www.kcl.ac.uk/newimages/sspp/Brazil-Flag-Main.jpg') left no-repeat, url('https://ak5.picdn.net/shutterstock/videos/2623775/thumb/1.jpg') right no-repeat;
    background-size: cover, cover;
  }
  .header-bar > h1 {
    padding: 1px;
    width: 60%;
  }
} 