body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

* {
  box-sizing: border-box;
}

fieldset {
  border: none;
  padding: 0;
  margin: 0;
}