.home {
    position: relative;
    margin-top: 15%;
    box-sizing: border-box;
    border: 2px solid black;
    border-radius: 5px;
    padding: 1%;
    float: right;
    margin-right: 10%;
    text-align: center;
}

.homeContainer {
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.informercial {
  position: relative;
  margin-top: 12.5%;
  display: inline-block;
  margin-left: 10%;
}

.blurb {
  margin: 0;
  display: inline-block;
  width: 400px;
  line-height: 34px;
}

.linkToRegister {
  font-size: 20px;
}

label {
  display: block;
}


#password {
  height: 40px;
  width: 200px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid black;
  padding: 8px;
}

#passwordConfirm {
  height: 40px;
  width: 200px;
  font-size: 16px;
  border-radius: 5px;
  border: 1px solid black;
  padding: 8px;
}

#username {
  height: 40px;
  width: 200px;
  font-size: 16px;
  border: 1px solid black;
  border-radius: 5px;
  padding: 8px;
}

.form-input{
  margin-bottom: 10px;
}

.login-button{
  height: 40px;
  width: 200px;
  font-size: 16px;
  border-radius: 5px;
  margin-top: 5px;
}


@media (max-width: 768px) {
  .homeContainer {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
  }

  .home {
    padding: 15px;
    margin: 50px 0;
  }

  .form-input{
    margin-bottom: 10px;
  }

  .informercial {
    max-width: 500px;
    margin: 35px 35px 15px 35px;
    text-align: center;
  }
}

@media (max-width: 400px) {
  .informercial {
    max-width: 300px;
  }

  .blurb {
    max-width: 300px;
  }
}
